import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Container from "components/Container";

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          title
        }
      }
    }
  `);
  return (
    <header>
      <Container>
        {<p>{data.site.siteMetadata.author}</p>}
        <ul>
          <li>
            <AniLink
              cover
              to="/"
              bg="#ccc"
              duration={0.7}
              className="header-link"
            >
              Home
            </AniLink>
          </li>
          <li>
            <AniLink
              cover
              direction="right"
              to="/about/"
              bg="#da819a"
              duration={0.7}
              className="header-link"
            >
              About
            </AniLink>
          </li>
          {/* <li>
            <AniLink
              cover
              to="/projects/"
              bg="#ecc474"
              duration={0.7}
              className="header-link"
            >
              Projects
            </AniLink>
          </li>
          <li>
            <AniLink
              cover
              direction="right"
              to="/resume/"
              bg="#a872a8"
              duration={0.7}
              className="header-link"
            >
              Resume
            </AniLink>
          </li> */}
          <li>
            <AniLink
              cover
              to="/contact/"
              bg="#527ca7"
              duration={0.7}
              className="header-link"
            >
              Contact
            </AniLink>
          </li>
        </ul>
      </Container>
    </header>
  );
};

export default Header;
