import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import mail from "assets/images/SVG/mail.svg";
import linked from "assets/images/SVG/linkedin.svg";
import github from "assets/images/SVG/github.svg";

import Container from "components/Container";

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
          linkedIn
          github
          emailAddress
        }
      }
    }
  `);
  return (
    <footer>
      <Container>
        <div className="block block--left">
          Made by {data.site.siteMetadata.author} using Gatsby
        </div>
        <div className="block block--center">
          Made with{" "}
          <span role="img" aria-label="heart">
            ❤️
          </span>{" "}
          using Gatsby.
        </div>
        <div className="footer__icons block block--right">
          <a
            href={data.site.siteMetadata.linkedIn}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="footer__icons-item">
              <use xlinkHref={`#${linked.id}`} />
            </svg>
          </a>

          <a
            href={data.site.siteMetadata.github}
            target="_blank"
            rel="noopener noreferrer"
          >
            <svg className="footer__icons-item">
              <use xlinkHref={`#${github.id}`} />
            </svg>
          </a>

          <a
            href={`mailto:${data.site.siteMetadata.emailAddress}`}
            rel="noopener noreferrer"
          >
            <svg className="footer__icons-item">
              <use xlinkHref={`#${mail.id}`} />
            </svg>
          </a>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
